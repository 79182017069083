.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
body {
  background-color: #f5f5f5;
  font-family: 'Georgia', serif;
}
header {
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 2px solid #000;

}

section {
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
}

section h2 {
  font-size: 24px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: right;
}

.story-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  margin-top: 40px;
}

footer {
  text-align: center;
  padding: 20px 0;
  border-top: 2px solid #000;
  font-style: italic;
}