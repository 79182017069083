/* Story.css */

.container {
    text-align: right;
  }
  
.button {
    background: none;
    border: none;
    cursor: pointer;
 }
  