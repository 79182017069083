.story-snippet {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    text-align: center;
  }
  
  .story-snippet:hover {
    transform: scale(1.02);
  }
  
  .story-snippet h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .story-snippet p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .story-snippet a {
    display: inline-block;
    padding: 8px 16px;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
  }
  
  .story-snippet a:hover {
    background-color: #333;
  }