.youtube-carousel {
    position: relative;
    margin-bottom: 40px;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    text-align: center;
  }
  
  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(248, 246, 246);
    color: #010101;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease-in-out;
  }
  
  .carousel-button:hover {
    background-color: rgba(238, 237, 141, 0.9);
  }
  
  .carousel-button.previous {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  .video-number {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(253, 253, 253);
    color: #000000;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
  }